<template>
  <v-btn color="secondary" class="back-menu-text" @click="resetQue">
    <v-card-text class="black--text font-weight-bold">
      RESET QUE
    </v-card-text>
  </v-btn>
</template>
<script>
export default {
  methods: {
    resetQue() {
        
      this.$http.get("/Menus/ResetQueue");
      alert("Que Reset")
    }
  }
};
</script>
